import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import "./index.less";
import commonService from "@/service/common";
import InfoDetial from "./compments/InfoDetial";
import ShopcoolTask from "./compments/ShopcoolTask";
import showIframe from "./compments/showIframe";
import showMicroApp from "./compments/showMicroApp";
import FinanceTaskDetail from "./compments/financeTaskDetail";
import EasyMeetingPayment from "./compments/easyMeetingPayment";
import BillingWorkbench from "./compments/billingWorkbench";
import SmartPay from "./compments/SmartPay";
import InfoCard from "./compments/MopModules/InfoCard";
import QueryCard from "./compments/MopModules/QueryCard";
import FooterBtnsCard from "./compments/MopModules/FooterBtnsCard";
import HotelDetails from "./compments/MopModules/HotelDetails";
import HoteDemand from "./compments/MopModules/HoteDemand/HoteDemand";
const components = {
  InfoDetial: InfoDetial,
  shopcoolTask: ShopcoolTask,
  financeTaskDetail: FinanceTaskDetail,
  easyMeetingPayment: EasyMeetingPayment,
  showIframe,
  showMicroApp,
  smartPay: SmartPay,
  billingWorkbench: BillingWorkbench,
  // mop的card
  InfoCard,
  QueryCard,
  FooterBtnsCard,
  HotelDetails,
  HoteDemand,
};
const IqueryDetailDrawer = (props) => {
  // 模块数组
  const [modules, setModules] = useState([]);
  // 抽屉标题
  const [drawerTitle, setDrawerTitle] = useState("");
  // 详情页
  const [detialsData, setDetialsData] = useState({});
  const [drawerWhite, setDrawerWhite] = useState(800);

  // 点击行返回数据
  // eslint-disable-next-line no-unused-vars
  const [clickVal, setClickVal] = useState({});
  const getMeetingInfo = async () => {
    setClickVal();
    let moudleParams = {
      collection: "cfg-web-modular",
      filter: {
        Application: "XCENTER",
        tenantId: "*",
        type: props.radioQueryData.pageType,
        tenantCode: props.drawerData?.tenantCode,
        abbrCode: {
          $in: [props.drawerData?.abbrCode || props.radioQueryData.pageType],
        },
      },
    };
    let tempParams = {
      collection: "cfg-ievent-infoCard-template",
      filter: {
        tenantCode: "hyq",
        tenantId: "*",
        platform: "web",
        type: props.radioQueryData.pageType,
      },
    };
    const res1 = await commonService.GetSettings(moudleParams);
    console.log(res1, "res1");
    res1.content.drawerWhite &&
      setDrawerWhite(res1.content.drawerWhite || "1000");
    res1.success && setModules(res1.content.modules);
    const res2 = await commonService.GetSettings(tempParams);
    console.log("res", res2);
    res2.success && setDetialsData({ ...res2.content });
    setDrawerTitle(res1.content.modules[0].title || "详情");
  };
  const onClose = () => {
    console.log(props.iqueryDetailDrawerVisible);
    props.iqueryDetailDrawerCallback({ type: "cancel" });
  };
  useEffect(() => {
    // console.log(props.radioQueryData);
    if (props.iqueryDetailDrawerVisible) {
      getMeetingInfo();
    }
  }, [props.iqueryDetailDrawerVisible, props.radioQueryData]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Drawer
      title={drawerTitle}
      placement="right"
      onClose={onClose}
      destroyOnClose={true}
      visible={props.iqueryDetailDrawerVisible}
      width={drawerWhite}
    >
      {modules?.map((x, index) => {
        const TagName = components[x.comName];
        return (
          <TagName
            key={index}
            dataInfo={detialsData}
            requestVal={props.drawerData}
            queryObject={props.queryObject}
            pageType={props.radioQueryData.pageType}
            microData={x.microData}
            iqueryDetailDrawerCallback={props.iqueryDetailDrawerCallback}
            modelInfo={modules}
            moduleItem={x}
            status={props.iqueryDetailDrawerVisible}
            defultCol={2}
          />
        );
      })}
    </Drawer>
  );
};
export default IqueryDetailDrawer;
