import React from "react";
import { Layout, Skeleton, Breadcrumb } from "antd";
// import PubSub from "pubsub-js";
import { withRouter, Link } from "react-router-dom";
import SiderNav from "@/components/SiderNav/index";
// import BreadCrumb from "@/components/BreadCrumb";
import HeaderBar from "@/components/HeaderBar/index.jsx";
import { MenuRoutes } from "@/router/menu-router";
import commonService from "@/service/common";
import SmartStorage from "@/utils/storage/index.js";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import "./index.less";

// import menus from "@/assets/mock/menus";

const { Sider, Header, Content, Footer } = Layout;

class Index extends React.Component {
  constructor(props) {
    super(props);
    window.tesetxx = this;
    this.state = {
      loading: false,
      menuLoading: true,
      collapsed: false,
      menus: null,
      setUserInfo: {
        dspName: "",
        depts: "",
        positions: [],
      },
      breadRoutes: [],
      // dynimcQueryData: null,
    };
  }

  setToggleState = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  componentDidMount = () => {
    this.getListSettings();
    this.getDataList();
    document.title = "智会云";

    // PubSub.subscribe("subscribeTopic", (msg, data) => {
    //   console.log(data, "11111");
    //   this.setState({
    //     collapsed: data,
    //   });
    // });
    // console.log(this.props.location.pathname);
    // if (
    //   this.props.location.pathname ===
    //   "/venueOperatingCenter/venueOperatingCenter/detail"
    // ) {
    //   this.setState({
    //     collapsed: true,
    //   });
    // } else {
    //   this.setState({
    //     collapsed: false,
    //   });
    // }
    if (SmartStorage.get("breadRoutes")) {
      this.setState({
        breadRoutes: SmartStorage.get("breadRoutes"),
      });
    }
  };
  // componentWillUnmount = () => {
  //   PubSub.unsubscribe("subscribeTopic");
  // };

  getListSettings = async () => {
    this.setState({ loading: true });
    let res = await commonService.GetMe();

    if (res && res.success) {
      this.setState({
        setUserInfo: {
          dspName: res.content?.dspName,
          userName: res.content?.userName,
          email: res.content?.email,
          mobile: res.content?.mobile,
        },
      });

      SmartStorage.set("userInfo", res.content);

      this.setState({ loading: false });
    }
  };
  // 获取菜单数据
  getDataList = () => {
    // let roleCodeList = ["EX.EMP.Viatris.Admin"];  // 开发阶段可放开，展示所有菜单
    // let roleCodeList = getCookie("x_role_codes").split(",");
    let params = {
      db: "smartx_tpm",
      collection: "cfg_iapp_sitemaps",
      filter: {
        Application: "XCENTER",
      },
      projection: {
        _id: 0,
        Sort: 0,
        Roles: 0,
        Tenant: 0,
        CreatedOn: 0,
      },
      sort: {
        Sort: 1,
      },
      limit: 1000,
    };

    this.setState({ menuLoading: true });
    commonService.QuerySettings(params).then((response) => {
      console.log('huiying',response);
      if (response && response.success && response.content) {

        console.log("====菜单数据====", response.content);
        SmartStorage.set("menus", response.content);
        this.setState({
          menus: response.content,
        });
      }
      this.setState({ menuLoading: false });
    });
  };
  // 子组件回调获取面包屑导航路由
  BreadCrumbRoutes = (routes) => {
    SmartStorage.set("breadRoutes", routes);
    this.setState({
      breadRoutes: routes,
    });
    // console.log(this.state.breadRoutes);
  };
  // 面包屑导航点击跳转
  itemRender = (route) => {
    return (
      <>
        {route.breadCrumbLevel === 1 ? (
          <span>{route.breadcrumbName}</span>
        ) : (
          <Link to={route.path}>{route.breadcrumbName}</Link>
        )}
      </>
    );
  };

  render() {
    return (
      <>
        {window.__MICRO_APP_BASE_ROUTE__ ? (
          <MenuRoutes menus={this.state.menus} />
        ) : (
          <Layout>
            {SmartStorage.get("userInfo") && (
              <Header className="header">
                <HeaderBar userInfo={this.state.setUserInfo} />
              </Header>
            )}
            <Layout className="sider_content">
              <Sider
                trigger={null}
                collapsible
                collapsed={this.state.collapsed}
                className="sider"
                width={220}
              >
                <div>
                  {this.state.menus && (
                    <SiderNav
                      collapsed={this.state.collapsed}
                      getRoutes={this.BreadCrumbRoutes}
                      menus={this.state.menus}
                    />
                  )}
                </div>
                <div className="collIcon">
                  {React.createElement(
                    this.state.collapsed
                      ? MenuUnfoldOutlined
                      : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: () => this.setToggleState(),
                    }
                  )}
                </div>
              </Sider>
              <Layout>
                <Content
                  style={{
                    maxHeight: "calc(100vh - 104px)",
                    overflowY: "scroll",
                  }}
                >
                  {this.state.breadRoutes.length > 0 && (
                    <Breadcrumb
                      itemRender={this.itemRender}
                      routes={this.state.breadRoutes}
                    />
                  )}
                  {this.state.loading || this.state.menuLoading ? (
                    <Skeleton active />
                  ) : (
                    <MenuRoutes menus={this.state.menus} />
                  )}
                </Content>
                <Footer>智会云 - POWERED BY TECH-MICE ©2014-NOW</Footer>
              </Layout>
            </Layout>
          </Layout>
        )}
      </>
    );
  }
}
export default withRouter(Index);
