import axios from "axios";
import qs from "qs";
import { notification, Modal } from "antd";
import { getCookie } from "tiny-cookie";
import SmartStorage from "../storage/index.js";
import publicMethod from "../public.js";

class SmartHttp {
  constructor(options) {
    // 是否阻塞其他请求
    this.isBlockErrorRequest = false;
    // 请求原型
    this.request = axios.create({
      baseURL: options.baseURL,
      changeOrigin:true,
      timeout: options.timeout || 10000, // 请求超时时间
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        // Route: window.location.pathname,
      },
    });

    this.injectRequest();
    this.injectResponse();
  }

  // 请求拦截
  injectRequest() {
    this.request.interceptors.request.use(
      (config) => {
        const token = getCookie("x_token") || getCookie("token");
        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }

        // 调用后端灰度服务
        const apiVersion = SmartStorage.get("apiVersion");
        if (apiVersion) {
          config.headers["Version"] = apiVersion;
        }

        // 账号权限隔离专用
        if (SmartStorage.get("ownerUserId")) {
          let tenantInfo = { userId: SmartStorage.get("ownerUserId") };

          config.headers["X-EventWorld-User"] = window.btoa(
            JSON.stringify(tenantInfo)
          );
        }

        // get请求添加时间搓
        if (config.method === "get") {
          config.params = {
            ...config.params,
            rnd: new Date().getTime(),
          };
        }

        if (
          config.data &&
          config.headers["Content-Type"] === "application/x-www-form-urlencoded"
        ) {
          config.data = qs.stringify(config.data);
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  // 响应拦截
  injectResponse() {
    this.request.interceptors.response.use(
      (response) => {
        const { data, status } = response;

        // 根据 status 状态拦截
        switch (status) {
          case 200:
            // 业务状态为false时做处理
            if (data.success === false) {
              let message = "";
              if (
                data.content &&
                typeof data.content !== "string" &&
                data.content.length > 0
              ) {
                data.content.forEach((item, index) => {
                  message += index + 1 + ". " + item.msg + "\n";
                });
              }

              notification.error({
                message: "提示",
                duration: message ? 10 : 4.5,
                description: (
                  <pre
                    style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                  >
                    {message || data.msg || "服务器错误，请稍后重试"}
                  </pre>
                ),
              });
            }
            return data;
          default:
            return Promise.reject(response);
        }
      },
      (error) => {
        console.log("error:", error);
        //if (this.isBlockErrorRequest) return;
        const { data, status } = error.response || {};

        if (error.response) {
          // 根据 status 状态拦截
          switch (status) {
            case 400:
              return data;
            case 401:
              this.isBlockErrorRequest = true;
              // message.error("请求未授权");
              Modal.error({
                title: "提示",
                content: "登录已过期，请您重新登录",
                okText: "重新登录",
                onOk: () => {
                  publicMethod.deleteLocalData();
                },
              });
              // return data;
              break;
            case 403:
              this.isBlockErrorRequest = true;
              // message.error("拒绝请求");
              notification.error({
                message: "提示",
                description:
                  data.msg || data.message || "拒绝请求，请稍后重试（403）",
              });
              // return data;
              break;
            case 500:
              this.isBlockErrorRequest = true;
              // message.error("服务器错误");
              notification.error({
                message: "提示",
                description:
                  data.msg || data.message || "服务器错误，请稍后重试（500）",
              });
              // return data;
              break;
            default:
              this.isBlockErrorRequest = true;
              console.log("http timeout");
              // message.error(status + "：服务器异常");
              notification.error({
                message: "提示",
                description:
                  data.msg || data.message || "服务器异常，请稍后重试",
              });
              return data;
          }
        } else {
          notification.error({
            message: "提示",
            description:
              "网络连接超时,请稍候再试\n如持续报错，请致电客服获得帮助",
          });
          return {};
        }
      }
    );
  }
}

export default SmartHttp;
