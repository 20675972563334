import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import commonService from "@/service/common";
import {Form, Modal, Descriptions, InputNumber, message} from "antd";
import { SmartStorage } from "@/utils";
import moment from "moment/moment";
const CarQuoteEditor = forwardRef((props, ref) => {
    const [form] = Form.useForm();
    const [selectedData, setSelectedData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = async (rowData) => {
        setSelectedData(rowData)
        form.setFieldsValue({ amount: rowData.amount });
        form.setFieldsValue({ price: rowData.amount / rowData.qty });
        setIsModalOpen(true);
    }
    const handleOk = () => {
        form.validateFields().then((values) => {
            let params = {
                supplierId: SmartStorage.get("detailRow").supplierId,
                itemId: selectedData.bussinessId,
                amount: form.getFieldValue('amount'),
                price: form.getFieldValue('price'),
                proposalId: SmartStorage.get("detailRow").proposalId,
                expenseId: selectedData.expenseId,
            };
            commonService.OfflineCarQuote(params).then(res => {
                if (res && res.success) {
                    message.success("修改预估报价成功")
                    props.updateChild()
                    setIsModalOpen(false);
                }
            })
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onChangePrice = () => {
        let price = form.getFieldValue('price')
        let total = price * selectedData.qty
        form.setFieldValue('amount', total)
    }
    const onChangeAmount = () => {
        let amount = form.getFieldValue('amount')
        let price = amount / selectedData.qty
        form.setFieldValue('price', price)
    }
    // 使用useImperativeHandle来暴露方法
    useImperativeHandle(ref, () => ({
        showModal,
    }));
    return (
        <Modal title="报价" width={800} maskClosable={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Form form={form} component={false} labelCol={{ span: 3 }} wrapperCol={{ span: 12 }} labelAlign="left">
                <Descriptions column={2}>
                    <Descriptions.Item label="需求类型">
                        {selectedData.demandType}
                    </Descriptions.Item>
                    <Descriptions.Item label="用车形式">
                        {selectedData.carModus}
                    </Descriptions.Item>
                    <Descriptions.Item label="数量">
                        {selectedData.qty} 台
                    </Descriptions.Item>
                    <Descriptions.Item label="单价">
                        {['标准需求'].includes(selectedData.demandType) ? (<>{
                            (Math.round( (Number(selectedData.amount) / Number(selectedData.qty)) * 100 ) / 100)
                        }</>) : '暂无'}
                    </Descriptions.Item>
                    <Descriptions.Item label="车型">
                        {selectedData.carType}
                    </Descriptions.Item>
                    {['特殊需求'].includes(selectedData.demandType) && (<>
                        <Descriptions.Item label="预计出发地">
                            {selectedData.departure}
                        </Descriptions.Item>
                        <Descriptions.Item label="预计目的地">
                            {selectedData.destination}
                        </Descriptions.Item>
                        <Descriptions.Item label="预计用车时间">
                            {moment(selectedData.dtStart).format('YYYY-MM-DD')}
                        </Descriptions.Item>
                    </>)}
                    <Descriptions.Item label="备注">
                        {selectedData.descr}
                    </Descriptions.Item>
                </Descriptions>
                <div className="info_center">
                    <Form.Item name='price' label='单价' style={{ margin: 0 }} rules={[ { required: true, message: '请填写单价', }, ]}>
                        <InputNumber style={{width: '100%'}} placeholder="请输入单价" onChange={onChangePrice} min={0} />
                    </Form.Item>
                    <Form.Item label='数量' style={{ margin: 0 }}>
                        {selectedData.qty}
                    </Form.Item>
                    <Form.Item name='amount' label='小计' style={{ margin: 0 }} rules={[ { required: true, message: '请输入小计', }, ]}>
                        <InputNumber style={{width: '100%'}} onChange={onChangeAmount} min={0} />
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
})
export default CarQuoteEditor
