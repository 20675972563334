import React, {
  // eslint-disable-next-line no-unused-vars
  useEffect,
  // eslint-disable-next-line no-unused-vars
  useState,
  Component,
  // eslint-disable-next-line no-unused-vars
  useRef,
  // eslint-disable-next-line no-unused-vars
  createRef,
} from "react";
// eslint-disable-next-line no-unused-vars
import { Input, Button, Empty, Modal, Mentions, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import commonService from "@/service/common";
import iSignalrM from "@/controllers/smart-signalr";
import { SmartStorage } from "@/utils";
// eslint-disable-next-line no-unused-vars
const { TextArea } = Input;
var refreshHub;
class CustomerDetail extends Component {
  constructor(props) {
   
    super(props);
    this.state = {
      ...props,
      choiseItem: props.choiseItem,
      inputValue: "",
      msgList: [],
      rebackDescr: "",
      open: false,
      memberText: "",
      memberList: [],
      selectIndex: "",
      member: null, //@消息
      btnText: "",
      loading:false
    };
    this.MudidInstance = React.createRef();
    console.log("this.MudidInstance", this.state.detila);
  }
 
  componentDidMount() {
    let _this=this
    let meetingCode=this.state.choiseItem.meetingcode;
    console.log(this.state.choiseItem,"meetingcode----")
    refreshHub= new iSignalrM({
        hubName: "SmartHub",
        queryParams:
          "group="+meetingCode,
        listeners: [
          {
            methodName: "igsk-car-mice-chat",
            method: function (data) {
              console.log(data,"data-----")
              _this.getMsg();
            },
          },
        ],
        host: process.env.REACT_APP_SIGNALER,
        // host: 'https://signalr.smartmice.cn'
      });
      refreshHub.install();
      this.getMsg();
      this.getMember();
  }
  componentWillUnmount() {
    this.setState = () => false;
    refreshHub.uninstall();
  }

  serch = () => {
    this.getMsg(this.state.inputValue);
  };
  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value });
  };
  handleTextAreaChange = (event) => {
    this.setState({ rebackDescr: event.target.value });
  };
  submitMsg = async () => {
    console.log("=======", this.MudidInstance.current);
    if (this.state.rebackDescr === "") {
      return;
    }
    let data = this.state.choiseItem;
    let params = {
      meetingCode: data.meetingcode || data.ExtNum,
      contentText: this.state.rebackDescr,
      replyMessageId: "", // 点回复按钮
      msgType: 1, //(TripConfirm = 1, Other = 2, StatementConfirm = 3)
      msgId: "", // 可空
      mediaId: "", // 可空
      currentMudId: SmartStorage.get("userInfo").userName,
      // tripUId: data.TripExtId,
    };
    if (this.state.member) {
      params.remindUserId = this.state.member.mudid;
      params.remindUserRole = this.state.member.role;
      params.remindUserName = this.state.member.name;
      params.remindUserHeadImg = this.state.member.headImg;
    }
    this.setState({ loading: true });
    const res = await commonService.group_message_send(params);
    if (res && res.success) {
      this.setState({ member: null, rebackDescr: "" });
      this.getMsg();
      this.MudidInstance.current.resetFields();
    }
    this.setState({ loading: false });
  };
  async getMsg(text) {
    console.log(this.state);
    let data = this.state.choiseItem;
    let params = {
      meetingCode: data.meetingcode || data.ExtNum,
      pageSize: 999,
      text: text ? text : "",
      tripUId: "",
      currentUserId: SmartStorage.get("userInfo").userName,
    };
    const res = await commonService.getOnlineGroupMessage(params);
    if (res && res.success && res.content) {
      this.setState({ msgList: res.content.messages || [] }, () => {
        document
          .getElementById(this.state.msgList[this.state.msgList.length - 1]?.id)
          ?.scrollIntoView({ behavior: "smooth", block: "center" });
      });
    }
  }
  checkItem = () => {
    this.setState({ open: false });
  };
  async getMember() {
    let data = this.state.choiseItem;
    let params = {
      meetingCode: data.meetingcode || data.ExtNum,
      text: this.state.memberText,
    };
    const res = await commonService.getMember(params);
    if (res && res.success && res.content) {
      let data = res.content.map((item) => {
        return {
          label: item.roleStr + " - " + item.name,
          value: item.roleStr + " - " + item.name,
          key: item.mudid,
          strValue: JSON.stringify(item),
        };
      });
      console.log(data);
      this.setState({ memberList: data || [] });
    }
  }
  // mentionChange = (v) => {
  //   console.log('=========去掉@',v);
  //   this.setState({ member: null})
  // }
  onSelect = (value) => {
    this.setState({ member: JSON.parse(value.strValue) });
  };
  MentionsChange = (value) => {
    if (value.indexOf("  ") > 0) {
      this.setState({
        rebackDescr: value.slice(value.indexOf("  ") + 2, value.length),
      });
    } else {
      this.setState({ member: null, rebackDescr: value });
    }
  };
  render() {
    return (
      <div className="customerDetail">
        <div className="headerBox">
          <p className="title">客服记录</p>
          <div className="searchBox">
            <Input
              placeholder=" 姓名，聊天内容"
              value={this.state.inputValue}
              onChange={this.handleInputChange}
            />
            <Button
              style={{ margin: "0 20px" }}
              type="primary"
              onClick={(e) => {
                this.serch(this.state.inputValue);
              }}
              icon={<SearchOutlined />}
            >
              搜索
            </Button>
          </div>
        </div>
        {this.state.msgList.length > 0 ? (
          <div className="msg_list">
            {this.state.msgList.map((item, index) => (
              <div className="msg_item" key={index} id={item.id}>
                <img
                  className="head"
                  src={require("../../../assets/img/person.png").default}
                  alt=""
                />
                <div className="chat">
                  <div className="msg">
                    <p className="name">
                      {item.fromUserName}
                      &emsp;
                      <span style={{ color: "#7f8c97", fontSize: "12px" }}>
                        {item.createdOn.replace(/T/g, " ")}
                      </span>
                    </p>
                    {item.departPlace && item.arrivePlace && (
                      <div className="tripItem">
                        <p>
                          {item.name || ""} -
                          {item.isSpeaker && <span>讲者</span>}
                          {!item.isSpeaker && (
                            <span className="green">参会者</span>
                          )}
                        </p>
                        <p>出发地址：{item.departPlace || ""}</p>
                        <p>
                          出发时间：{item.departDate.replace(/T/g, " ") || ""}
                        </p>
                        <p>到达地址：{item.arrivePlace || ""}</p>
                      </div>
                    )}
                    <p>{item.contentText}</p>
                    {item.remindUserRoleStr && (
                      <p>
                        @{" "}
                        {item.remindUserRoleStr ||
                          // eslint-disable-next-line no-useless-concat
                          "" + " - " + item.remindUserName ||
                          ""}
                      </p>
                    )}
                    {item.voiceMsg && (
                      <audio
                        className="audio"
                        preload="auto"
                        controls="controls"
                        src={item.voiceMsg}
                      ></audio>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Empty />
        )}
        {
          <Form name="nest-messages" ref={this.MudidInstance}>
            <Form.Item label="" name="mentions">
              <Mentions
                onChange={this.MentionsChange}
                onSelect={this.onSelect}
                rows={3}
                style={{ width: "100%" }}
              >
                {this.state.memberList.map((item, index) => (
                  <Mentions.Option value={item.value} strValue={item.strValue} key={item.key}>
                    {item.label}
                  </Mentions.Option>
                ))}
              </Mentions>
            </Form.Item>
          </Form>
        }
        {
          <div className="bottomBox">
            <Button type="primary" disabled={this.state.loading} onClick={this.submitMsg}>
              发送
            </Button>
          </div>
        }
      </div>
    );
  }
}
export default CustomerDetail;
