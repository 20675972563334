import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Form, Input, Modal, message} from 'antd'
import commonService from "@/service/common";

/**
 * 编辑酒店审核关键词
 * author: Lt.Ethan Cho, on Mar.14 2024
 */
export default forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [RowData, setRowData] = useState({});
    const [formInstance] = Form.useForm();
    const onCancel = () => {
        setVisible(false)
    }
    const onOk = () => {
        const {nameKeyword, addressKeyword} = formInstance.getFieldsValue();
        const params = {
            "CustomerId": RowData.customerId,
            "NameKeyword": nameKeyword,
            "AddressKeyword": addressKeyword
        }
        commonService.saveHotelAuditEdit(params).then(res => {
            if(res.success) {
                message.success("保存成功");
                props.reloadData();
                onCancel();
            }
        })
    }
    // expose to parent component
    useImperativeHandle(ref, () => ({
        handle: (btn, rowKeys, selectedRows, data) => {
            console.log(selectedRows);
            formInstance.setFieldsValue(selectedRows);
            setRowData(selectedRows);
            setVisible(true);
        }
    }))
    return (
        <Modal
            title="编辑"
            destroyOnClose
            visible={visible}
            width="35%"
            onOk={onOk}
            onCancel={onCancel}
        >
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <p>
                    客户： {RowData.displayName}
                </p>
                <p>配置名称： {RowData.catalog}</p>
            </div>
            <Form
                name="basic"
                layout="vertical"
                form={formInstance}
            >
                <Form.Item
                    label="酒店名称限制关键字（示例：XX，XX）"
                    name="nameKeyword">
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="酒店地址限制关键字（示例：XX，XX）"
                    name="addressKeyword">
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    );
})
