import { Button, Modal, message } from 'antd';
import commonService from "@/service/common";
import React, { memo, useState, useImperativeHandle, forwardRef, useContext } from 'react';
import XformContext from "./xformContext"
import { createForm } from '@formily/core'
import { createSchemaField } from '@formily/react'
import { getCookie } from "tiny-cookie";

import {
    Form,
    FormItem,
    FormLayout,
    Input,
    Select,
    Password,
    Cascader,
    DatePicker,
    Submit,
    Space,
    FormGrid,
    Upload,
    ArrayItems,
    Editable,
    FormButtonGroup,
} from '@formily/antd'


const App = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const { XformData, reloadData } = useContext(XformContext);
    const [formSchema, setFormSchema] = useState({
        form: {},
        schema: {}
    })

    const showModal = async () => {
        // 拉取formSchema
        console.log("====XformData====", XformData)
        getFromSchema().then(formSchema => {
            console.log("====加载formSchema成功====", formSchema)
            setFormSchema(formSchema)
            setTimeout(() => {
                setOpen(true);
            }, 10);
        }).catch(err => {
            console.log("====加载formSchema失败====", err)
        })

    };

    const getFromSchema = async () => {
        let [belongApp, formName] = XformData.formRef.split(".")
        let res = await commonService.common("post", "/ndata/api/xquery/getQueryData", {
            "QueryId": "5005449b-29a0-4021-b7de-f6d94cb99cc0",
            "cluster": "default",
            "parameters": {
                "deconstruct": false,
                _query: {
                    "form.belongApp": belongApp,
                    "form.formName": formName
                }
            }
        })
        if (res && res.success && res.content) {
            if (res.content.rows[0]) {
                return res.content.rows[0]
            } else {
                throw new Error("没有找到schema")
            }
        } else {
            throw new Error("查询条件失败")
        }
    }

    const handleOk = () => {


        // 检查表单验证是否通过
        form.validate().then(async () => {
            console.log("====验证表单通过====", form)
            let formDataProto = form.getState().values
            console.log("====表单原始数据====", formDataProto)
            let originFormData = Object.keys(formDataProto).reduce((acc, key) => {
                if (!/^_.*_$/.test(key)) {
                    acc[key] = formDataProto[key];
                }
                return acc;
            }, {});
            console.log("====排除仅显示字段后数据====", originFormData)

            // 开始最后提交前的数据处理
            console.log("====开始执行提交前函数====",);
            let functionScope = {
                $formData: structuredClone(originFormData),
                $record: structuredClone(XformData.record),
                $gateway: process.env.REACT_APP_GATEWAY,
                $token: getCookie("x_token"),
                $http: commonService,
                $message: message
            }
            //执行函数
            try {
                if (formSchema.form.onFormOk) {
                    let functionString = formSchema.form.onFormOk.match(/{{(.*?)}}/s);
                    (new Function(`${functionString[0]}`))(functionScope);
                }
            } catch (error) {
                console.log("=======执行提交前函数error=======", error)
            }

            // 提交表单数据
            console.log("====开始提交表单数据====", formSchema.form.actionURL, functionScope);
            let res = await commonService.common("post", formSchema.form.actionURL, functionScope.$formData)
            if (res && res.success) {
                setOpen(false);
                reloadData()
                message.success(res.msg || "操作成功");
            } else {
                message.error(res.msg || "操作失败");
            }

        }).catch((error) => {
            console.log("=======error=======", error)
        })

    };
    const handleCancel = () => {
        setOpen(false);
    };

    // 暴露方法给父级应用
    useImperativeHandle(ref, () => ({
        showModal
    }))


    //渲染表单
    const form = createForm({
        validateFirst: true,
    })
    let scope = {
        $token: getCookie("x_token"),
        $gateway: process.env.REACT_APP_GATEWAY,
        $http: commonService,
        $message: message
    }

    Object.defineProperty(scope, '$record', {
        get() {
            return XformData.record;
        },
        enumerable: true,
        configurable: true
    });

    const SchemaField = createSchemaField({
        components: {
            FormItem,
            FormGrid,
            FormLayout,
            Input,
            DatePicker,
            Cascader,
            Upload,
            Select,
            Password,
            Space,
            ArrayItems,
            Editable,
        },
        scope
    })

    return (
        <>
            <Modal
                title={formSchema.form.formTitle}
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    form={form}
                    labelCol={formSchema.form.labelCol}
                    wrapperCol={formSchema.form.wrapperCol}
                >
                    <SchemaField schema={formSchema.schema} />
                </Form>
            </Modal>
        </>
    );
});
let MemoApp = memo(App);
export default MemoApp;