import React from 'react';
import { BrowserRouter as Router, Switch, Route, /* Redirect */ } from 'react-router-dom';
import Layout from '../pages/Layout';
import Authorization from "../pages/Authorization";
import IqueryPageList from "../pages/HomePage";
import GMVNotification from "../pages/GMVNotification";
const RootRouter = () => {
	return (
		<Router>
				<Switch>
					{/*<Route path="/publicQuery" component={IqueryPageList} />*/}
					<Route path="/micro-apps/publicQuery" component={IqueryPageList} />
					<Route path="/authorization" component={Authorization} />
					<Route path="/gmvNotification" component={GMVNotification} />
					<Route path="/" component={Layout} />
					{/* <Redirect from="/" to="/emp" /> */}
				</Switch>
			</Router>
	);
};

export default RootRouter;
