import { forwardRef } from "react";
import { Button, Badge, Popover } from "antd";
// eslint-disable-next-line no-unused-vars
import { getCookie } from "tiny-cookie";
import { LinkOutlined } from "@ant-design/icons";
// eslint-disable-next-line no-unused-vars
import commonService from "@/service/common";
const SmartPayButton = forwardRef((props, ref) => {
  const content = (
    <div className="payDetail">
      <div>
        <Button className="payBtn" type="primary">
          作废
        </Button>
        <p>取号时间:</p>
        <p>用餐人:</p>
        <p>状态:</p>
        <p>方式:</p>
      </div>
      <div>
        <Button className="payBtn" type="primary">
          作废
        </Button>
        <p>取号时间:</p>
        <p>用餐人:</p>
        <p>状态:</p>
        <p>方式:</p>
      </div>
      <div>
        <Button className="payBtn" type="primary">
          作废
        </Button>
        <p>取号时间:</p>
        <p>用餐人:</p>
        <p>状态:</p>
        <p>方式:</p>
      </div>
    </div>
  );
  return (
    <div>
      <Popover
        placement="left"
        title="排队情况"
        content={content}
        trigger="click"
      >
        <Badge count={5}>
          <Button type="link">
            排队详情
            <LinkOutlined />
          </Button>
        </Badge>
      </Popover>
    </div>
  );
});
export default SmartPayButton;
