import React, { forwardRef, useImperativeHandle, useState } from "react";
import { message, Modal, Form, InputNumber, Input, Button, Radio } from "antd";
import { UploadFiles } from "smart-filer-react";
import SmartQueryReact from "smart-iquery-react";
import commonService from "@/service/common";
import { SmartStorage } from "@/utils";
import { UploadOutlined } from "@ant-design/icons";
// eslint-disable-next-line no-unused-vars
import { getCookie } from "tiny-cookie";
const { TextArea } = Input;
// eslint-disable-next-line no-unused-vars
const { confirm } = Modal;
const DiffReconciliation = forwardRef((props, ref) => {
  console.log("====iqueryHomePage动态子组件====", props);
  const [form] = Form.useForm();
  const iSignalr = process.env.REACT_APP_SIGNALER;
  const gatewayPath = process.env.REACT_APP_GATEWAY;
  // const getQueryDataDetail = [{ "queryId": props.queryObject.variables.queryId4Detail, "queryId4Count": props.queryObject.variables.queryId4Count, "cluster": props.queryObject.variables.cluster}]
  // const getQueryDataDetail = [
  //   { queryId: "712", queryId4Count: "713", cluster: "default" },
  // ];
  const [getQueryDataDetail, setGetQueryDataDetail] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [diffModalVisible, setDiffModalVisible] = useState(false);

  const [btnData, setBtnData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [record, setRecord] = useState({});
  const [uploadProps, setUploadProps] = useState();
  const [fileList, setFileList] = useState([]);

  useImperativeHandle(ref, () => ({
    handle: (btn, rowKeys, selectedRows, data) => {
      console.log("====iqueryHomePage动态子组件暴露方法====", btn, rowKeys, selectedRows, data);
      // console.log(btn, "btns");
      setGetQueryDataDetail([btn.request]);
      setBtnData(btn);
      // setSelectedRows(selectedRows || data);
      // eslint-disable-next-line no-eval
      if (btn.val || btn.actionCode) {
        eval(btn.val || btn.actionCode)(btn, rowKeys, selectedRows, data);
      } else {
        setModalVisible(true);
      }
    },
  }));

  // eslint-disable-next-line no-unused-vars
  const createOrder = () => {
    setModalVisible(true);
  };
  const cancelModal = () => {
    setModalVisible(false);
  };
  const cancelDiffModal = () => {
    form.resetFields();
    setDiffModalVisible(false);
  };
  const saveData = async () => {
    let res
    if (btnData.actionType == 'subQuery') {
      // demo parameters
      //   "parameters": {
      //     "api": "/api/CheckAccount/Monthly/CreateMonthlyBatch",
      //     "body": {
      //         "curTenantId": "{{getCookie('tenant_id')}}"
      //     }
      // },
      let params = btnData.parameters.body;
      params = JSON.stringify(params)
      params = JSON.parse(params, (key, value) => {
        console.log(key, value)
        if (/^\{\{[^{}]*\}\}$/.test(value)) {
          const expression = value.replace(/^\{\{|\}\}$/g, '');
          let res
          try {
            res = eval(expression)
          } catch (error) {
            res = ""
          }
          return res
        } else {
          return value
        }
      })


      res = await commonService.common("post", btnData.parameters.api, params)
    } else {
      res = await commonService.FinaceDiff(selectedRows);
    }
    if (res && res.success) {
      message.success(res.msg || "操作成功");
    }
    props.reloadData();
    cancelModal();
  };
  const saveUpdateData = () => {
    form.validateFields().then(async (values) => {
      console.log("====提交表单数据====", structuredClone(values));

      if (btnData.actionCode === "closeDiffTask" && fileList.length < 1) {
        message.error("支持文件必填");
        return;
      }
      let params = values;
      params.diffid = record.diffId;
      let res = await commonService[
        btnData.actionCode === "closeDiffTask"
          ? "FinaceCloseDiff"
          : "FinaceUpdateDiff"
      ](params);
      if (res && res.success) {
        message.success(res.msg || "操作成功");
        cancelDiffModal();
        props.reloadData();
      }
    });
  };
  const setMappingData = (btn, data) => {
    let params =
      btn?.variables?.UploadMappingData &&
      JSON.parse(JSON.stringify(btn.variables.UploadMappingData));
    let mappingData = {};
    for (let key in params) {
      let val = params[key];
      console.log(val, data[val]);
      if (key !== "Catalog") {
        mappingData[val] = data[val];
      } else {
        mappingData.catalog = params.Catalog;
      }
    }
    console.log(mappingData);
    let updata = {
      accept:
        ".jpg, .jpeg, .jfif, .gif, .bmp, .png, .doc, .docx, .xls, .xlsx, .xlsm, .pdf, .ppt, .pptx, .csv, .ofd, .eml, .msg", // 可上传文件类型
      type: "list",
      gateway: gatewayPath,
      showUploadList: {
        showDownloadIcon: true,
        showPreviewIcon: true,
        showRemoveIcon: true,
      },
      isMapping: true,
      filterData: mappingData,
      uploadCallback: (file, fileList) => {
        setFileList(fileList);
        console.log("App uploadCallback:", file, fileList);
      },
      removeCallback: (file, fileList) => {
        setFileList(fileList);
        console.log("App removeCallback:", file, fileList);
      },
    };
    console.log(updata);
    setUploadProps(updata);
    setRecord(data);
    setDiffModalVisible(true);
  };
  // eslint-disable-next-line no-unused-vars
  const closeDiffTask = (btn, selectedRowKeys, selectedRows1, data) => {
    setMappingData(btn, data);
  };
  // eslint-disable-next-line no-unused-vars
  const adjustAccount = (btn, selectedRowKeys, selectedRows1, data) => {
    setMappingData(btn, data);
  };
  // 行数据选中回调
  const returnSelectedRows = (rowsId, rows) => {
    console.log(rows);
    setSelectedRowKeys(rowsId);
    setSelectedRows(rows);
  };

  let goDetail = (link, rows) => {
    console.log(link, rows);

    // 通过link 匹配rows对象中值等于link的 那个key 名 
    //rows 是一个对象
    let key = Object.keys(rows).find((key) => rows[key] === link);
    // 如果key + Url 作为key 在rows对象中不是undefined的或者空字符串 则 link 从新赋值为 rows对象中的那个值
    if (rows[key + "Url"]) {
      link = rows[key + "Url"]
    }

    window.open(link, "_blank");

  }
  return (
    <div>
      <Modal
        title={btnData.actionName}
        width="1200px"
        open={modalVisible}
        onOk={saveData}
        destroyOnClose
        onCancel={cancelModal}
        footer={btnData.disableFooter}
      >
        <SmartQueryReact
          gatewayPath={gatewayPath}
          iSignalr={iSignalr}
          goDetail={goDetail}
          returnSelectedRows={returnSelectedRows}
          getQueryData={getQueryDataDetail}
        ></SmartQueryReact>
      </Modal>
      <Modal
        title={btnData.actionName}
        width="600px"
        open={diffModalVisible}
        onOk={saveUpdateData}
        destroyOnClose
        onCancel={cancelDiffModal}
      >
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={{ isUpdate: false }}
          autoComplete="off"
        >
          <Form.Item label="订单编号">{record.orderNum}</Form.Item>
          <Form.Item label="客户">{record.name}</Form.Item>
          <Form.Item label="会议名称">{record.name1}</Form.Item>
          <Form.Item label="原订单金额">{record.oriAmount}</Form.Item>
          <Form.Item
            name="amount"
            label="调整后金额"
            rules={[
              {
                required: btnData.actionCode === "closeDiffTask" ? false : true,
                message: "请输入调整后金额!",
              },
            ]}
          >
            {btnData.actionCode === "closeDiffTask" ? (
              "-"
            ) : (
              <InputNumber
                style={{
                  width: "300px",
                }}
                placeholder="请输入调整后金额"
              />
            )}
          </Form.Item>
          <Form.Item
            name="remark"
            label="备注"
            rules={[
              {
                required: btnData.actionCode === "closeDiffTask" ? true : false,
                message: "请输入备注!",
              },
            ]}
          >
            <TextArea
              style={{
                width: "300px",
              }}
              placeholder="请输入备注"
              allowClear
            />
          </Form.Item>
          <Form.Item
            label="支持文件"
            rules={[
              {
                required: btnData.actionCode === "closeDiffTask" ? true : false,
              },
            ]}
          >
            <UploadFiles {...uploadProps}>
              <Button type="primary" icon={<UploadOutlined />}>
                导入
              </Button>
            </UploadFiles>
          </Form.Item>
          {
            btnData.actionCode === "closeDiffTask" ?
              <Form.Item label="是否更新金额" name="isUpdate">
                <Radio.Group>
                  <Radio value={true}> 是 </Radio>
                  <Radio value={false}> 否 </Radio>
                </Radio.Group>
              </Form.Item>
              : null
          }
        </Form>
      </Modal>
    </div>
  );
});

export default DiffReconciliation;
