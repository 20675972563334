import React, { useState, useEffect } from "react";
import { Form, Col, DatePicker, Select } from "antd";
import { ThunderboltOutlined } from "@ant-design/icons";
import defaultSettings from "./defaultSettings";
import moment from "moment";
import "./index.less";
import dateFast from "@/assets/mock/dateFast/DateFast.json";

const { Option } = Select;

const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings;

const { RangePicker } = DatePicker;

const XRangePicker = (props) => {
  // console.log(props);
  const { isRest } = props;
  const item = props.item;
  const [propNanme, setPropName] = useState("");

  // function defaultChangeClick(e, item) {
  //   console.log(e);
  //   console.log(item);
  //   if (e) {
  //     const items = e.map((item) => {
  //       item = moment(item._d).format(item.format);
  //       return item;
  //     });
  //     item.callBack && props.callback(items, item);
  //   }
  // }
  // 时间参数范围
  const dataScopeFnc = (code) => {
    if (code === "month") {
      item.startTime = moment().subtract(30, "days").format(item.format);
    } else if (code === "week") {
      item.startTime = moment().subtract(7, "days").format(item.format);
    } else {
      item.startTime = moment().subtract(30, "days").format(item.format);
    }
  };
  const setDefaultTitem = () => {
    item.endTime = moment().format(item.format);
    if (item.hasOwnProperty("dataScope")) {
      dataScopeFnc(item.dataScope);
    } else {
      item.startTime = moment().subtract(30, "days").format(item.format);
    }
    setPropName(item.name);
    // 动态设置时间属性值
    if (propNanme !== "") {
      let setTimeVal = {};
      setTimeVal[propNanme] = [
        moment(item.startTime, item.format),
        moment(item.endTime, item.format),
      ];
      props.formInfo.setFieldsValue({ ...setTimeVal });
    }
  };
  useEffect(() => {
    setDefaultTitem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, propNanme]);
  useEffect(() => {
    if (isRest === 1) {
      setDefaultTitem();
      handleChange(0)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRest]);
  // const disabledDate = (current) => {
  //   if (item.startDate || item.endDate) {
  //     return (
  //       current < moment(item.startDate).format(item.format) ||
  //       current > moment(item.endDate).format(item.format)
  //     );
  //   } else {
  //     return false;
  //   }
  // };
  // 通过快捷选择过来的时间进行范围时间计算
  const dateCalculate = (dateCode) => {
    let dataVal = {};
    let startData = "";
    let endData = "";
    switch (dateCode) {
      case 1:
        startData = moment().startOf("week").format(item.format);
        endData = moment().endOf("week").format(item.format);
        break;
      case 2:
        startData = moment().startOf("month").format(item.format);
        endData = moment().endOf("month").format(item.format);
        break;
      case 3:
        startData = moment().startOf("year").format(item.format);
        endData = moment().endOf("year").format(item.format);
        break;
      case 4:
        startData = moment()
          .month(moment().month() - 1)
          .startOf("month")
          .format(item.format);
        endData = moment()
          .month(moment().month() - 1)
          .endOf("month")
          .format(item.format);
        break;
      case 5:
        startData = moment()
          .year(moment().year() - 1)
          .startOf("year")
          .format(item.format);
        endData = moment()
          .year(moment().year() - 1)
          .endOf("year")
          .format(item.format);
        break;
      case 6:
        startData = moment()
          .week(moment().week() - 1)
          .startOf("week")
          .format(item.format);
        endData = moment()
          .week(moment().week() - 1)
          .endOf("week")
          .format(item.format);
        break;
      case 7:
        startData = moment().subtract(7, "days").format(item.format);
        endData = moment().format(item.format);
        break;
      case 8:
        startData = moment().subtract(14, "days").format(item.format);
        endData = moment().format(item.format);
        break;
      case 9:
        startData = moment().subtract(30, "days").format(item.format);
        endData = moment().format(item.format);
        break;
      case 10:
        startData = moment().subtract(60, "days").format(item.format);
        endData = moment().format(item.format);
        break;
      case 11:
        startData = moment().subtract(90, "days").format(item.format);
        endData = moment().format(item.format);
        break;
      case 12:
        let year = moment().year() - 1;
        if ((year % 4 === 0 && !(year % 100 === 0)) || year % 400 === 0) {
          startData = moment().subtract(366, "days").format(item.format);
        } else {
          startData = moment().subtract(365, "days").format(item.format);
        }
        endData = moment().format(item.format);
        break;
      default:
        startData = moment().startOf("month").format(item.format);
        endData = moment().endOf("month").format(item.format);
        break;
    }
    dataVal[propNanme] = [
      moment(startData, item.format),
      moment(endData, item.format),
    ];
    props.formInfo.setFieldsValue({ ...dataVal });
  };
  const handleChange = (val) => {
    dateCalculate(val);
  };
  return (
    <>
      {item.startTime !== "" && (
        <Col className="colClass">
          <Form.Item
            name={item.name}
            rules={item.rules}
            labelCol={{ span: item.lcol || defaultLabelSpan }}
            wrapperCol={{ span: item.wcol || defaultWrapperSpan }}
            label={item.label}
            labelAlign="right"
          >
            <RangePicker
              style={{ width: "100%" }}
              showTime={item.showTime}
              format={item.format}
              disabledTime={item.disabledTime}
              disabled={item.disabled && true}
              placeholder={
                item.placeholder || ["请选择开始日期", "请选择结束日期"]
              }
            />
          </Form.Item>
          <Form.Item>
            <Select
              onChange={handleChange}
              allowClear
              placeholder="快捷选择"
              suffixIcon={<ThunderboltOutlined />}
            >
              {dateFast.map((item) => (
                <Option value={item.value} key={item.value}>
                  {isRest ===1?"":item.text}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
    </>
  );
};

export default XRangePicker;
