import React, { useEffect, useState } from "react";
import { Form, Col, Select } from "antd";
import defaultSettings from "./defaultSettings";
import commonService from "@/service/common";
import { SmartStorage } from "@/utils";
import _ from "lodash";

const { defaultLabelSpan, defaultWrapperSpan } = defaultSettings;

const { Option } = Select;

const XSelect = (props) => {
  const item = props.item;
  const [options, setOptions] = useState(
    typeof item.dataSource === "string"
      ? JSON.parse(item.dataSource)
      : item.dataSource || []
  );
  const [loading, setLoading] = useState(false);

  const defaultChangeClick = (e, item, sData) => {
    let optionData = null;
    if (sData?.constructor === Array) {
      // 多选时，此处应该是返回数组对象
      optionData = sData;
    } else {
      // 单选，参数返回对象
      if (sData) {
        const filteredArr = options.filter(
          (v) => (v.Val || v.Id || v.value || v.Value) === sData?.value
        );
        optionData = Object.assign(filteredArr[0] || {}, sData);
      } else {
        optionData = [];
      }
    }

    item.callBack && props.callback(e, item, optionData);
  };
  // 下拉框展开回调
  const onDropdownVisibleChange = (open) => {
    if (open && item.searchKeyName) {
      getOptions(item.dataSource);
    }
  };

  const getOptions = async (source, searchKey) => {
    //setOptions置空会造成页面select加载下拉框没有数据，先注释，回调功能具体用法根据业务再做调整
    setOptions([]);
    if (source.method && source.url) {
      let params = source.params || {};
      let method = source.method;
      let url = source.url;
      if (searchKey) {
        // params.filter[item.searchKeyName] = "/" + searchKey + "/";
        params.filter[item.searchKeyName] = {
          $regex: searchKey,
          $options: "i",
        }; // 模糊搜索不区分大小写
      } else {
        params.filter && delete params.filter[item.searchKeyName];
      }

      setLoading(true);
      let res = await commonService.common(method, url, params);
      setLoading(false);
      if (res && res.success && res.content) {
        let arr = res.content.map((v) => {
          if (v.Txt) {
            return v;
          } else {
            return { ...v, Txt: v.dspName, Val: v.userId };
          }
        });
        item.name === "visitedBy" && SmartStorage.set("visitedBy", arr);

        if (arr.length === 0) {
          arr.push({ Txt: "暂无", Val: "暂无" });
        } else if (item.name === "subOrgName") {
          arr.unshift({
            Val: "none",
            Txt: "暂无",
          });
        }
        if (item.optionsSort === "localeCompare") {
          console.log(arr);
          arr.sort(function (a, b) {
            return a["Name"].localeCompare(b["Name"]);
          });
        }

        if (item.optionName) {
          setOptions(arr[0][item.optionName]);
        } else {
          setOptions(arr);
        }
      }
    }
  };
  useEffect(() => {
    if (item.dataSource && item.name !== "subOrgName") {
      // getOptions(item.dataSource);
      setOptions(
        typeof item.dataSource === "string"
          ? JSON.parse(item.dataSource)
          : item.dataSource
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {item.hidden !== true && (
        <Col span={item.span}>
          <Form.Item
            name={item.name}
            rules={item.rules}
            labelCol={{ span: item.lcol ? item.lcol : defaultLabelSpan }}
            wrapperCol={{ span: item.wcol ? item.wcol : defaultWrapperSpan }}
            label={item.label}
            hidden={item.hidden}
            labelAlign={item.labelAlign || "right"}
          >
            {item.showSearch ? (
              <Select
                style={{ width: "100%" }}
                mode={item.mode || "default"}
                loading={loading}
                disabled={item.disabled && true}
                labelInValue={item.labelInValue}
                allowClear={!item.allowClear}
                placeholder={item.placeholder}
                showSearch={item.showSearch}
                optionFilterProp="children"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                defaultValue={item.defaultValue}
                filterOption={(inputValue, option) =>
                  option.children &&
                  option.children
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
                onFocus={() => {
                  props.onFocus && props.onFocus({ item: item });
                  if (item.name === "subOrgName") {
                    getOptions(item.dataSource);
                  }
                }}
                onDropdownVisibleChange={onDropdownVisibleChange}
                onSearch={_.debounce((value) => {
                  // setLoading(true)
                  if (item.searchKeyName) {
                    getOptions(item.dataSource, value);
                  }
                }, 500)}
                filterSort={(optionA, optionB) => {
                  // console.log("slect  seach", item);
                  if (
                    !item.dataSource?.params?.sort ||
                    Object.keys(item.dataSource?.params?.sort).length > 0
                  ) {
                    optionA.children &&
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase());
                  }
                }}
                onChange={(e, sData) => defaultChangeClick(e, item, sData)}
              >
                {options.map((obj, index) => {
                  return (
                    <Option key={obj.val} value={obj.val}>
                      {obj.txt}
                    </Option>
                  );
                })}
              </Select>
            ) : (
              <Select
                style={{ width: "100%" }}
                mode={item.mode || "default"}
                loading={loading}
                disabled={item.disabled && true}
                labelInValue={item.labelInValue}
                allowClear={!item.allowClear}
                placeholder={item.placeholder}
                optionFilterProp="children"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                defaultValue={item.defaultValue}
                filterOption={(inputValue, option) =>
                  option.children &&
                  option.children
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
                onFocus={() => {
                  props.onFocus && props.onFocus({ item: item });
                  if (item.name === "subOrgName") {
                    getOptions(item.dataSource);
                  }
                }}
                onDropdownVisibleChange={onDropdownVisibleChange}
                filterSort={(optionA, optionB) => {
                  // console.log("slect  seach", item);
                  if (
                    !item.dataSource?.params?.sort ||
                    Object.keys(item.dataSource?.params?.sort).length > 0
                  ) {
                    optionA.children &&
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase());
                  }
                }}
                onChange={(e, sData) => defaultChangeClick(e, item, sData)}
              >
                {options.map((obj, index) => {
                  return (
                    <Option key={obj.val} value={obj.val}>
                      {obj.txt}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          {item.tips ? <div className="form-item-tips">{item.tips}</div> : ""}
        </Col>
      )}
    </>
  );
};

export default XSelect;
